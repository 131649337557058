import logo from "./logo.svg";
import "./App.css";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    const input = document.querySelector("#input");
    input.focus();
    input.addEventListener("input", () => {
      input.value = input.value.replaceAll("&amp;", "&");
    });
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        token-helper.
      </header>
      <div className="App-body">
        <span className="BigText">Token :</span>
        <textarea id="input" className="TextArea" rows={5} cols={100} />
        <span className="MediumText">
          Replace <code>&amp;amp;</code> by <code>&</code>.
        </span>
      </div>
    </div>
  );
}

export default App;
